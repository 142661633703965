/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import padEnd from 'lodash/padEnd';
import get from 'lodash/get';
import { Navigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Heading,
  Container,
  Box,
  VStack,
  Text,
  HStack,
  Tag,
  Skeleton,
} from '@chakra-ui/react';
import { AppLogo } from '../../components/Nav/logo';

import {
  selectAuth,
  clientJs,
  selectDisplayInfo,
  selectDisplayId,
} from '../slice';
import { useAuthenticateQuery, useRegisterDisplayMutation } from '../service';

const bgImage = new URL('../../assets/waves-2.webm', import.meta.url);

const useRetryRegisterDisplay = ({ delayInSeconds = 10, maxAttempts = 3 }) => {
  const displayInfo = useSelector(selectDisplayInfo);
  const attempts = React.useRef<number>(0);
  const waitForRetry = React.useRef<boolean>(false);
  const [registerDisplay, state] = useRegisterDisplayMutation();

  const retry: typeof registerDisplay = React.useCallback(
    async (displayId?: string) => {
      if (attempts.current >= maxAttempts || waitForRetry.current) return;
      attempts.current += 1; // Increment the attempts counter
      try {
        waitForRetry.current = true;
        const display = await registerDisplay({ displayId, ...displayInfo }); // Try to run the passed async function
        if (display?.error) throw new Error('shouldRetry');
        waitForRetry.current = false;
      } catch (err) {
        setTimeout(() => {
          waitForRetry.current = false;
          retry(displayId);
        }, delayInSeconds * 1000); // Schedule a retry after the delay
      }
    },
    [delayInSeconds, maxAttempts]
  );

  return [
    retry,
    {
      ...state,
      isRetrying: !state.isSuccess && attempts.current < maxAttempts,
    },
  ] as const;
};

// NOTE: displayID needs to be atleast 10 characters long
const RegisterDisplay = () => {
  const { displayId: urlDisplayId } = useParams();
  const { hostname } = new URL(window.location.href);
  const { isAuthenticated } = useSelector(selectAuth);
  const currentDisplayId = useSelector(selectDisplayId);
  const [
    registerDisplay,
    { isError: isRegistrationError, isRetrying, data: registrationData },
  ] = useRetryRegisterDisplay({
    delayInSeconds: 1,
    maxAttempts: 5,
  });

  React.useEffect(() => {
    const fingerprint = clientJs.getFingerprint();
    const genID =
      currentDisplayId ||
      (clientJs.isMobile()
        ? `rn_${crypto?.randomUUID()}`
        : `fp_${fingerprint}`);
    // NOTE: displayID needs to be atleast 10 characters long, so we pad it with the fingerprint
    const guid = padEnd(urlDisplayId || genID, 10, fingerprint?.toString());
    registerDisplay(guid);
  }, [urlDisplayId, currentDisplayId]);

  const displayCode = get(registrationData, 'code');
  const displayId = get(registrationData, 'displayId', currentDisplayId);

  // start polling for authentication
  useAuthenticateQuery(
    { displayId, code: displayCode },
    { skip: !displayCode, pollingInterval: 2000 }
  );

  if (isAuthenticated) return <Navigate to="/" />;
  return (
    <>
      <Box h="100vh" bg="#0056f873" color="white" overflow="hidden">
        <Container maxW="10xl" centerContent h="full" as={VStack} py={"20rem"}>
          <AppLogo />
          {isRegistrationError && !isRetrying ? (
            <>
              <Heading my="3rem" textAlign="center">
                An unknown error occurred during display registration.
              </Heading>
              <VStack align="start" spacing={10}>
                <Text fontSize="2rem">
                  Please contact WelcomeTv support if this issue persists.
                </Text>
              </VStack>
            </>
          ) : (
            <>
              <>
                <Heading as={'h1'} size={'4xl'} my={'3rem'} fontWeight={"normal"}>
                  Welcome to My Welcome TV's Registration Page
                </Heading>
                <Text fontSize="2rem">Login to your My Welcome TV account and use the code provided below to add this new display.</Text>
                <Box>

                  <Skeleton
                    width={"600px"}
                    height="140px"
                    isLoaded={!isRetrying || !!displayCode}
                  >
                    <Heading textAlign={'center'} size={'4xl'} my={'3rem'} fontWeight={600}>{displayCode}</Heading>
                  </Skeleton>
                </Box>
                <Text fontSize="2rem">For more information about My Welcome TV visit our website</Text>
                <Text textDecoration={"underline"} fontSize="1.5rem"><a target='_blank' href="https://mywelcometv.com">https://MyWelcomeTV.com</a></Text>
              </>
            </>
          )}
        </Container>
      </Box>
      <video
        autoPlay
        loop
        muted
        style={{
          objectFit: 'cover',
          height: '100vh',
          width: '100vw',
          filter: 'blur(0.5rem) opacity(1)',
          position: 'fixed',
          top: '0',
          left: '0',
          zIndex: '-1',
        }}
      >
        <source src={bgImage.href} type="video/webm"></source>
      </video>
    </>
  );
};

export default RegisterDisplay;
